import { Blurhash } from 'react-blurhash';

export default function ImageThumbnail({ url, blurhash }) {
  /**
   * @param {Event} event
   */
  function handleLoad(event) {
    event.target.classList.remove('opacity-0');
  }

  return (
    <div className="relative">
      {blurhash ? (
        <Blurhash
          className="rounded-lg overflow-hidden"
          hash={blurhash}
          width={240}
          height={240}
          style={{ display: 'block' }}
        />
      ) : (
        <div className="rounded-lg w-60 h-60 bg-midnight-40"></div>
      )}
      <img
        src={`${
          process.env.REACT_APP_MATRIX_HOMESERVER
        }/_matrix/media/r0/thumbnail/${url.substring(
          6,
        )}?width=240&height=240&method=crop`}
        className="w-60 h-60 absolute inset-0 rounded-lg aspect-square transition-opacity opacity-0"
        onLoad={handleLoad}
      />
    </div>
  );
}
