import { MessengerContext } from '@/contexts';
import { ClientEvent } from 'matrix-js-sdk';
import { useCallback, useContext, useEffect, useState } from 'react';

export default function useMessengerNotificationCounter() {
  const { matrixClient } = useContext(MessengerContext);
  const [notificationCounter, setNotificationCounter] = useState(0);

  const updateCounter = useCallback(() => {
    const total = matrixClient
      .getRooms()
      .reduce((agg, room) => agg + room.getUnreadNotificationCount(), 0);
    setNotificationCounter(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to track matrixClient
  }, []);

  useEffect(() => {
    matrixClient.on(ClientEvent.Sync, updateCounter);
    return () => {
      matrixClient.off(ClientEvent.Sync, updateCounter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't want to track matrixClient
  }, [updateCounter]);

  return notificationCounter;
}
