import { MessengerContext, useMessengerContext } from '@/contexts';
import { AuthGuard } from '@/router/guards';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export default function DashboardScene() {
  const context = useMessengerContext();

  useEffect(() => {
    context.start();
    return () => context.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- start on first render, stop when leave
  }, []);

  return (
    <AuthGuard>
      <MessengerContext.Provider value={context}>
        <Outlet />
      </MessengerContext.Provider>
    </AuthGuard>
  );
}
