// This file contains code borrowed from
// https://github.com/matrix-org/matrix-react-sdk/src/HtmlUtils.tsx

import EMOJIBASE_REGEX from 'emojibase-regex';

// Anything outside the basic multilingual plane will be a surrogate pair
const SURROGATE_PAIR_PATTERN = /([\ud800-\udbff])([\udc00-\udfff])/;
// And there a bunch more symbol characters that emojibase has within the
// BMP, so this includes the ranges from 'letterlike symbols' to
// 'miscellaneous symbols and arrows' which should catch all of them
// (with plenty of false positives, but that's OK)
const SYMBOL_PATTERN = /([\u2100-\u2bff])/;

const EMOJI_SEPARATOR_REGEX = /[\u200D\u200B\s]|\uFE0F/g;
const BIGEMOJI_REGEX = new RegExp(`^(${EMOJIBASE_REGEX.source})+$`, 'i');

/**
 * Return true if the given string contains emoji
 * Uses a much, much simpler regex than emojibase's so will give false
 * positives, but useful for fast-path testing strings.
 */
function mightContainEmoji(string) {
  return (
    !!string &&
    (SURROGATE_PAIR_PATTERN.test(string) || SYMBOL_PATTERN.test(string))
  );
}

/**
 * Check if the string contains only emojis
 * @param {string} string
 * @returns {boolean}
 */
export default function onlyEmojis(string) {
  if (!mightContainEmoji(string)) return false;

  let result = false;

  // Remove zero width joiner, zero width spaces and other spaces in body
  // text. This ensures that emojis with spaces in between or that are made
  // up of multiple unicode characters are still counted as purely emoji
  // messages.
  const trimmed = string.replace(EMOJI_SEPARATOR_REGEX, '');
  const match = BIGEMOJI_REGEX.exec(trimmed);
  result = match?.[0]?.length === trimmed.length;
  return result;
}
